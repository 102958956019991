import React, { Component } from 'react'
import { graphql } from 'gatsby'
import he from 'he'
import Seo from '../components/seo'
import RenderBlock from '../utils/render-block'

class PageTemplate extends Component {

  render() {
    let page = this.props.data.wpPage

    return (
      <>
        <Seo
          path={page.slug === "home" ? "/" : `/${page.slug}/`}
          title={he.decode(page.seo.title)}
          description={page.seo.metaDesc}
          keywords={page.seo.metaKeywords}
          schema={page.seo.schema.raw}
          bodyClass={page.slug}
        />
        { page.acf.components && page.acf.components.map((el, i) => RenderBlock(el.fieldGroupName, el, i, 'page'))  }
      </>
    )
  }
}

export const pageQuery = graphql`
  query ($id: String!) {
    wpPage(id: {eq: $id}) {
      title
      slug
      seo {
        metaDesc
        metaKeywords
        title
        schema {
          raw
        }
        opengraphImage {
          localFile {
            publicURL
          }
        }
      }
      acf {
        components {
          ... on WpPage_Acf_Components_TitleButton {
            fieldGroupName
            title
            button {
              text
              link
            }
          }
          ... on WpPage_Acf_Components_Projects {
            fieldGroupName
            projects {
              ... on WpPost {
                title
                link
                featuredImage {
                  node {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          quality: 100
                          width: 1600
                          formats: [AUTO, WEBP]
                          placeholder: DOMINANT_COLOR
                        )
                      }
                    }
                  }
                }
                acf {
                  svgLogo
                  description
                  video
                }
              }
            }
          }
          ... on WpPage_Acf_Components_OurServices {
            fieldGroupName
            title
            content
            video {
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      quality: 100
                      width: 1600
                      formats: [AUTO, WEBP]
                      placeholder: DOMINANT_COLOR
                    )
                  }
                }
              }
              video
            }
            branding
            digital
            marketing
            integrate {
              text
              link
            }
            clients {
              text
              link
            }
            team {
              name
            }
          }
          ... on WpPage_Acf_Components_ContactPage {
            fieldGroupName
            title
            content
            brief {
              title
              button {
                text
                link
              }
            }
          }
        }
      }
    }
  }
`

export default PageTemplate
